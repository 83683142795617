import { Button } from "@twilio-paste/core/dist/button";
import { ChevronDoubleLeftIcon } from "@twilio-paste/icons/esm/ChevronDoubleLeftIcon";
import { ChevronDoubleRightIcon } from "@twilio-paste/icons/esm/ChevronDoubleRightIcon";
import { FC } from "react";
import { useAppState } from "../../../hooks/useAppState/useAppState";

export const ToggleNavButton: FC = () => {
	const { appState, appDispatch } = useAppState();
	const onClick = () => {
		appDispatch({ type: "set-is-nav-open", isNavOpen: !appState.isNavOpen  });
	};

	return (
		<Button variant="secondary" onClick={onClick}>
			{appState.isNavOpen ? <ChevronDoubleLeftIcon decorative={false}  title="Collapse Navigation" /> :
				<ChevronDoubleRightIcon decorative={false} title="Expand Navigation" />}
		</Button>)
};