import { ConferenceType } from "@mmc/conferencing-booking-client";
import { Box } from "@twilio-paste/core/dist/box";
import { Label } from "@twilio-paste/core/dist/label";
import { Select, Option } from "@twilio-paste/core/dist/select";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { ProperCase } from "../../../functions/general";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";

export const ConferenceTypeFilter: FC = () => {
	const { appState, appDispatch } = useAppState();
	const { isLoading } = useAdminContext();
	const [conferenceType, setConferenceType] = useState<string>(appState.filters.conferenceType);

	const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setConferenceType(e.target.value);
		appDispatch({ type: "set-filters", filters: { ...appState.filters, conferenceType: e.target.value as ConferenceType } });
	};

	useEffect(() => {
		setConferenceType(appState.filters.conferenceType);
	}, [ appState.filters.conferenceType ]);

	return (<Box width="150px">
		<Label htmlFor="conferenceTypeFilter">Conference Type</Label>
		<Select onChange={onChange} name="conferenceTypeFilter" id="conferenceTypeFilter" value={conferenceType} disabled={isLoading}>
			<Option key="none" value="">-</Option>
			{Object.entries(ConferenceType).map(([ key, value ]) => (
				<Option key={key} value={value}>{ProperCase(key.toLowerCase().split("_").join(" "))}</Option>
			))}
		</Select>
	</Box>)
}