import { BOOKING_COLUMN_COOKIE_NAME, DEFAULT_BOOKING_COLUMN_NAMES } from "../constants";
import { BookingColumn } from "../hooks/useBookingColumns/useBookingColumns";

export const GetBookingColumns = (): BookingColumn[] => {
	const cookie =  document.cookie.match(new RegExp(BOOKING_COLUMN_COOKIE_NAME + "=([^;]+)"));
	if(cookie)
	{
		return JSON.parse(cookie[1]);
	}
	else
	{
		return DEFAULT_BOOKING_COLUMN_NAMES;
	}
}