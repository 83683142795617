import { FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { MediaObject, MediaBody, MediaFigure } from "@twilio-paste/core/media-object";
import { Menu, MenuButton, MenuItem, MenuSeparator, useMenuState } from "@twilio-paste/core/menu";
import { MoreIcon } from "@twilio-paste/icons/esm/MoreIcon";
import { Text } from "@twilio-paste/core/text";
import { Separator } from "@twilio-paste/core/separator";
import { useNavigate } from "react-router-dom";
import { LogoutModal } from "../LogoutModal/LogoutModal";
import { HEADERS_HEIGHT } from "../../constants";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Role } from "@mmc/conferencing-booking-client";

export const Header: FC = () => {
	const menu = useMenuState();
	const { appState } = useAppState();
	const navigate = useNavigate();
	return (
		<Box style={{ background: "rgb(40, 42, 43)" }} as="header" color="colorTextInverse" position="sticky" top="0"
			display="flex" justifyContent="flex-end" width="100%" zIndex="zIndex90" height={HEADERS_HEIGHT}>
			<Box width={"100%"} display="flex">
				<Box display={["flex", "flex", "none"]} minWidth="sizeSidebar" paddingX="space60" paddingY="space50">
					<MenuButton {...menu} variant="reset" size="reset">
						<MoreIcon decorative={false} title="More options" />
								Menu
					</MenuButton>
					<Menu {...menu} aria-label="Preferences">
						<MenuItem {...menu} onClick={() => navigate("bookings")}>Bookings</MenuItem>
						<MenuItem {...menu} onClick={() => navigate("calendar")}>Calendar</MenuItem>
						{appState.role === Role.Admin && <>
							<MenuSeparator {...menu} />
							<MenuItem {...menu} onClick={() => navigate("hosts")}>Hosts</MenuItem>
							<MenuItem {...menu} onClick={() => navigate("skills")}>Skills</MenuItem>
						</>}
					</Menu>
				</Box>
				<Box display={["none", "none", "flex"]} minWidth="sizeSidebar" paddingX="space60" paddingY="space50">
					<MediaObject verticalAlign="center">
						<MediaBody>
							<Box as="span" display="block" fontSize="fontSize40" fontWeight="fontWeightBold" lineHeight="lineHeight40">
								<Text as="h1" fontSize="fontSize40" fontWeight="fontWeightSemibold" color="colorTextInverse">Admin Portal</Text>
							</Box>
						</MediaBody>
						<MediaFigure>
							<Separator orientation="vertical" horizontalSpacing="space40" />
						</MediaFigure>
					</MediaObject>
				</Box>
				<Box display="flex" flex="1" paddingX="space70" paddingY="space50" flexDirection="row-reverse">
					<LogoutModal />
				</Box>
			</Box>
		</Box>
	);
};
