import { Button } from "@twilio-paste/core/dist/button";
import { FC } from "react";
import { initialAppState } from "../../../core/appReducer";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";

export const ClearFiltersButton: FC = () => {
	const { appDispatch } = useAppState();
	const { isLoading } = useAdminContext();

	const onClick = () => {
		appDispatch({ type: "set-filters", filters: initialAppState.filters });
	};

	return (<Button variant="link" onClick={onClick} disabled={isLoading} >Clear</Button>)
};