import { createContext, FC, useCallback, useState } from "react";
import { ReactElementProps } from "../../interface";
import { BookingFilters, ConferenceFilters, BookingContextType } from "../../types/sdk";
import { Booking, BookingApi, BookingDetail, ConferenceApi, Configuration as BookingClientConfiguration, Host, HostApi, HostAuthApi, HostDetail, ListBooking, ListConference, ListHost, ListSkillSet, SkillSet, SkillSetApi, SkillSetDetail } from "@mmc/conferencing-booking-client";
import { BOOKING_API_URL } from '../../constants';
import { BearerTokenMiddleware } from '@mmc/apiclient-auth-middleware';

export const BookingContext = createContext<BookingContextType>(null!);

export const BookingProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
  const [bookingApi, setBookingApi] = useState<BookingApi>();
  const [conferenceApi, setConferenceApi] = useState<ConferenceApi>();
  const [hostApi, setHostApi] = useState<HostApi>();
  const [hostAuthApi, setHostAuthApi] = useState<HostAuthApi>();
  const [skillSetApi, setSkillSetApi] = useState<SkillSetApi>();
  const [providerReady, setProviderReady] = useState<boolean>(false);

  const connect = useCallback((token: string) => {
    try {
      const bookingApi = new BookingApi(new BookingClientConfiguration({
        basePath: BOOKING_API_URL,
        middleware: [new BearerTokenMiddleware(token)]
      }));
      setBookingApi(bookingApi);
      const conferenceApi = new ConferenceApi(new BookingClientConfiguration({
        basePath: BOOKING_API_URL,
        middleware: [new BearerTokenMiddleware(token)]
      }));
      setConferenceApi(conferenceApi);
      const hostApi = new HostApi(new BookingClientConfiguration({
        basePath: BOOKING_API_URL,
        middleware: [new BearerTokenMiddleware(token)]
      }));
      setHostApi(hostApi);
      const hostAuthApi = new HostAuthApi(new BookingClientConfiguration({
        basePath: BOOKING_API_URL,
        middleware: [new BearerTokenMiddleware(token)]
      }));
      setHostAuthApi(hostAuthApi);
      const skillSetApi = new SkillSetApi(new BookingClientConfiguration({
        basePath: BOOKING_API_URL,
        middleware: [new BearerTokenMiddleware(token)]
      }));
      setSkillSetApi(skillSetApi);
      setProviderReady(true);
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const listBooking = useCallback(async (pageSize?: number, lastKey?: string, { hostId, status, region, search, startDateFrom, startDateTo }: BookingFilters = {}): Promise<ListBooking> => {
    const bookings = await bookingApi?.bookingGet({
      startDateFrom: startDateFrom,
      startDateTo: startDateTo,
      pageSize: pageSize,
      lastKey: lastKey,
      hostId: hostId,
      status: status,
      region: region,
      search: search
    });
    if (bookings) {
      return bookings;
    } else {
      throw new Error("No Bookings");
    }
  }, [bookingApi]);

  const listConference = useCallback(async (bookingId?: string, pageSize?: number, lastKey?: string, { startDateFrom, startDateTo, hostId, status, conferenceType, search }: ConferenceFilters = {}): Promise<ListConference> => {
    if (bookingId) {
      const conferences = await conferenceApi?.bookingBookingIdConferenceGet({
        bookingId: bookingId,
        startDateFrom: startDateFrom,
        startDateTo: startDateTo,
        hostId: hostId,
        status: status,
        conferenceType: conferenceType,
        search: search,
        pageSize: pageSize,
        lastKey: lastKey
      });
      if (conferences) {
        return conferences;
      } else {
        throw new Error("No Conferences");
      }
    } else {
      const conferences = await conferenceApi?.conferenceGet({
        startDateFrom: startDateFrom,
        startDateTo: startDateTo,
        hostId: hostId,
        status: status,
        conferenceType: conferenceType,
        search: search,
        pageSize: pageSize,
        lastKey: lastKey
      });
      if (conferences) {
        return conferences;
      } else {
        throw new Error("No Conferences");
      }
    }

  }, [conferenceApi]);

  const getHost = useCallback(async (hostId: string): Promise<Host> => {
    const host = await hostApi?.hostHostIdGet({
      hostId: hostId
    });
    if (host) {
      return host;
    } else {
      throw new Error("No Host");
    }
  }, [hostApi]);

  const getBooking = useCallback(async (bookingId: string): Promise<Booking> => {
    const booking = await bookingApi?.bookingBookingIdGet({
      bookingId: bookingId
    });
    if (booking) {
      return booking;
    } else {
      throw new Error("No Booking");
    }
  }, [bookingApi]);

  const listHost = useCallback(async (pageSize?: number, lastKey?: string): Promise<ListHost> => {

    // Avoid JS error on initial load of login page
    if (!hostApi) {
      return {
        hosts: [],
        meta: {
          pageSize: 0
        }
      };
    }

    const hosts = await hostApi.hostGet({
      pageSize: pageSize,
      lastKey: lastKey
    });
    if (hosts) {
      return hosts;
    } else {
      throw new Error("No Hosts");
    }
  }, [hostApi]);

  const listSkillSets = useCallback(async (pageSize?: number, lastKey?: string): Promise<ListSkillSet> => {
    const skillSets = await skillSetApi?.skillsetGet({
      pageSize: pageSize,
      lastKey: lastKey
    });
    if (skillSets) {
      return skillSets;
    } else {
      throw new Error("No Skill Sets");
    }
  }, [skillSetApi]);

  const getSkill = useCallback(async (skillSetId: string): Promise<SkillSet> => {
    const skillSet = await skillSetApi?.skillsetSkillSetIdGet({
      skillSetId: skillSetId
    });
    if (skillSet) {
      return skillSet;
    } else {
      throw new Error("No Skills");
    }
  }, [skillSetApi]);

  const resetPassword = async (hostId: string): Promise<void> => {
    await hostAuthApi?.hostHostIdPasswordResetPut({
      hostId: hostId
    });
  }

  const removeHost = async (hostId: string): Promise<void> => {
    await hostApi?.hostHostIdDelete({
      hostId: hostId
    });
  }

  const createHost = async (hostObject: HostDetail): Promise<Host> => {
    const host = await hostApi?.hostPost({
      hostDetail: hostObject
    });
    if (host) {
      return host;
    } else {
      throw new Error("No Host Created");
    }
  };

  const updateBooking = async (bookingId: string, requestBody: BookingDetail): Promise<Booking> => {
    const booking = await bookingApi?.bookingBookingIdPatch({
      bookingId: bookingId,
      bookingDetail: requestBody
    });
    if (booking) {
      return booking;
    } else {
      throw new Error("No Booking Updated");
    }
  };

  const updateSkill = async (skillSetId: string, skillSetDetail: SkillSetDetail): Promise<SkillSet> => {
    const skillSet = await skillSetApi?.skillsetSkillSetIdPatch({
      skillSetId: skillSetId,
      skillSetDetail: skillSetDetail
    });
    if (skillSet) {
      return skillSet;
    } else {
      throw new Error("No Skill Updated");
    }
  };

  const updateHost = async (hostId: string, hostDetail: HostDetail): Promise<Host> => {
    const host = await hostApi?.hostHostIdPatch({
      hostId: hostId,
      hostDetail: hostDetail
    });
    if (host) {
      return host;
    } else {
      throw new Error("No Host Updated");
    }
  };

  return <BookingContext.Provider value={{ providerReady, connect, listBooking, listConference, getHost, getBooking, listHost, listSkillSets, getSkill, resetPassword, removeHost, createHost, updateBooking, updateSkill, updateHost }}>{children}</BookingContext.Provider>;
}
