import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Tr, Td } from "@twilio-paste/core/table";
import { BookingStatus, Role } from "@mmc/conferencing-booking-client";
import { Button } from "@twilio-paste/button";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { BookingConferences } from "../../types/sdk";
import { BookingColumn } from "../../hooks/useBookingColumns/useBookingColumns";
import { PERMANENT_BOOKING_COLUMNS } from "../../constants";
import { RenderRow } from "./RenderRow";
import { AcceptBookingButton } from "../Buttons/AcceptBookingButton/AcceptBookingButton";
import { ResetBookingStateButton } from "../Buttons/ResetBookingStateButton/ResetBookingStateButton";

interface TableBooking {
	booking: BookingConferences;
}

export const BookingRow: FC<TableBooking> = ({ booking }) => {
	const navigate = useNavigate();
	const { appState } = useAppState();
	const permenantFields = PERMANENT_BOOKING_COLUMNS
		.filter(x => !(((x.fieldPath === "conference" && x.name === "conferenceName") || (x.fieldPath === "conference" && x.name === "startDate"))));

	const optionalFields = appState.columns
		.filter(x => !(x.fieldPath === "conference" && x.name === "conferenceId"));

	return (
		<Tr>
			{[...permenantFields, ...optionalFields]
				.map((column: BookingColumn, index: number) => {
					if (column.value) {
						return <RenderRow key={`${booking.bookingId}-${column.fieldPath}.${column.name}`} column={column} index={index} data={booking} />;
					} else {
						return null;
					}
				})}
			<Td textAlign="right">
				{booking.status === BookingStatus.Pending &&
					appState.role === Role.Admin && (
					<AcceptBookingButton bookingId={booking.bookingId} />
				)}
				{booking.status === BookingStatus.Completed &&
					appState.role === Role.Admin && (
					<ResetBookingStateButton bookingId={booking.bookingId} />
				)}
				{booking.status === BookingStatus.Accepted &&
					appState.role === Role.Admin && (
					<Button variant="secondary" size="small" onClick={() => { navigate(`/bookings/${booking.bookingId}`); }}>
						<UserIcon decorative={true} />	Assign Hosts
					</Button>
				)}
			</Td>
		</Tr>
	);
};
