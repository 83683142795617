import { BookingStatus } from "@mmc/conferencing-booking-client";
import { Box } from "@twilio-paste/core/dist/box";
import { Label } from "@twilio-paste/core/dist/label";
import { Select, Option } from "@twilio-paste/core/dist/select";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { ProperCase } from "../../../functions/general";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";

export const StatusFilter: FC = () => {
	const { appState, appDispatch } = useAppState();
	const { isLoading } = useAdminContext();
	const [status, setStatus] = useState<string>(appState.filters.status);

	const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setStatus(e.target.value);
		appDispatch({ type: "set-filters", filters: { ...appState.filters, status: e.target.value as BookingStatus } });
	};

	useEffect(() => {
		setStatus(appState.filters.status);
	}, [ appState.filters.status ]);

	return (<Box width="150px">
		<Label htmlFor="statusFilter">Booking Status</Label>
		<Select onChange={onChange} name="statusFilter" id="statusFilter" value={status} disabled={isLoading}>
			<Option key="none" value="">-</Option>
			{Object.entries(BookingStatus).map(([ key, value ]) => (
				<Option key={key} value={value}>{ProperCase(key.toLowerCase())}</Option>
			))}
		</Select>
	</Box>)
}