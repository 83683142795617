import { HostItem } from "@mmc/conferencing-booking-client";
import { Box } from "@twilio-paste/core/dist/box";
import { Label } from "@twilio-paste/core/dist/label";
import { Select, Option } from "@twilio-paste/core/dist/select";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";
export const HostFilter: FC = () => {
	const { appState, appDispatch } = useAppState();
	const { isLoading, hosts } = useAdminContext();
	const [host, setHost] = useState<string>(appState.filters.hostId);

	const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setHost(e.target.value);
		appDispatch({ type: "set-filters", filters: { ...appState.filters, hostId: e.target.value } });
	};

	useEffect(() => {
		setHost(appState.filters.hostId);
	}, [ appState.filters.hostId ]);

	return (<Box width="150px">
		<Label htmlFor="hostFilter">Host</Label>
		<Select onChange={onChange} name="hostFilter" id="hostFilter" value={host} disabled={isLoading}>
			<Option key="none" value="">-</Option>
			{hosts.map((host: HostItem) => (
				<Option key={host.hostId} value={host.hostId}>{host.firstName} {host.lastName}</Option>
			))}
			<Option key="unassigned" value="unassigned">Unassigned</Option>
		</Select>
	</Box>)
}