import {FC, useCallback, useEffect, useState} from "react";
import { Table, TBody, Th, THead, Tr, Td } from "@twilio-paste/core/table";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/button";
import { HostItem, ListHost } from "@mmc/conferencing-booking-client";
import { useNavigate } from "react-router-dom";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Badge } from "@twilio-paste/core/badge";
import {
	Pagination,
	PaginationItems,
	PaginationArrow,
	PaginationLabel
} from "@twilio-paste/core/pagination";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { ChevronUpIcon } from "@twilio-paste/icons/esm/ChevronUpIcon";
import { UnsortedIcon } from "@twilio-paste/icons/esm/UnsortedIcon";
import { Flex } from "@twilio-paste/flex";
import { PAGE_SIZE } from "../../constants";

export const HostsList: FC = () => {
	const [hosts, setHosts] = useState<HostItem[]>([]);
	const [loaded, setLoaded] = useState<boolean>(false);
	const { listHost } = useBookingContext();
	const [currentPage, setCurrentPage] = useState(1);
	const [firstHostID, setFirstHostId] = useState<string>();
	const [toggleState, setToggleState] = useState<string>("none");
	const navigate = useNavigate();

	const handleHost = useCallback(async (pageSize: number, hostId?: string) => {
		try {
			const data: ListHost = await listHost(pageSize, hostId);
			setHosts(data.hosts);
			setLoaded(true);
		} catch (error) {
			console.error(error);
		}
	}, [listHost, setHosts, setLoaded]);


	const sortData = (data: HostItem[], sortBy: string, sortOrder: string) => {
		const sortedItems = data.sort((a: any, b: any) => {
			if (sortBy === "firstName") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return b.firstName.localeCompare(a.firstName);
				} else {
					setToggleState("desc");
					return a.firstName.localeCompare(b.firstName);
				}
			} else if (sortBy === "lastName") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return b.lastName.localeCompare(a.lastName);
				} else {
					setToggleState("desc");
					return a.lastName.localeCompare(b.lastName);
				}
			} else if (sortBy === "email") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return b.email.localeCompare(a.email);
				} else {
					setToggleState("desc");
					return a.email.localeCompare(b.email);
				}
			} else if (sortBy === "role") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return a.role.localeCompare(b.role);
				} else {
					setToggleState("desc");
					return b.role.localeCompare(a.role);
				}
			} else if (sortBy === "skill") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return a.skill.localeCompare(b.skill);
				} else {
					setToggleState("desc");
					return b.skill.localeCompare(a.skill);
				}
			}
			return 0;
		});
		return setHosts(() => [...sortedItems]);
	}

	useEffect(() => {
		handleHost(PAGE_SIZE, "");
	}, [handleHost]);


	const goToNextPage = (event: any) => {
		setCurrentPage((page) => Math.min(page + 1));
		const lastHostId = hosts[hosts.length - 1].hostId;
		if (currentPage === 1) {
			setFirstHostId("");
		} else {
			setFirstHostId(hosts && hosts[0].hostId);
		}
		handleHost(PAGE_SIZE, lastHostId);
		event.preventDefault();
	};

	const goToPreviousPage = (event: any) => {
		setCurrentPage((page) => Math.max(page - 1, 1));
		if (currentPage === 2) {
			handleHost(PAGE_SIZE, "");
		} else {
			handleHost(PAGE_SIZE, firstHostID);
		}
		event.preventDefault();
	};

	const handleRoleText = (role: string) => {
		// return word after _ and capatilise first letter
		const roleText = role.split("_").map((word) => {
			return word.charAt(0).toUpperCase() + word.slice(1);
		});
		// switch (roleText[0]) {
		// case "Admin":
		// 	return ["Admin", "new"];
		// case "Host":
		// 	return ["Host", "success"];
		// case "Speaker":
		// 	return ["Speaker", "warning"];
		// case "Viewer":
		// 	return ["Viewer", "neutral"];
		// default:
		// 	return roleText[1];
		// }
		return roleText[1];
	}

	return (
		<>
			<Box
				marginY="space60"
				display="flex"
				justifyContent="end"
				flexDirection="row"
				columnGap="space60"
				alignItems="end"
			>
				<Box minWidth="100px">
					<Button
						fullWidth
						variant="primary"
						onClick={() => navigate("/hosts/add")}
					>
						Add a New Host
					</Button>
				</Box>
			</Box>
			<Table>
				<THead>
					<Tr>
						<Th >
							<Flex vAlignContent="center">
								First name{" "}
								<Box marginLeft="space30" display="flex" alignItems="center">
									{toggleState === "desc" ? (
										<Button
											variant="secondary_icon"
											size="reset"
											onClick={() => sortData(hosts, "firstName", "asc")}
										>

											<ChevronUpIcon
												decorative={false}
												size="sizeIcon10"
												title="Ascending"
											/>

										</Button>
									) : (
										<Button
											variant="secondary_icon"
											size="reset"
											onClick={() => sortData(hosts, "firstName", "desc")}
										>
											{toggleState === "none" ? (
												<UnsortedIcon
													decorative={false}
													size="sizeIcon10"
													title="Ascending"
												/> ) : (
												<ChevronDownIcon
													decorative={false}
													size="sizeIcon10"
													title="Descending"
												/>
											)}
										</Button>
									)}
								</Box>
							</Flex>
						</Th>
						<Th><Flex vAlignContent="center">
								Last name{" "}
							<Box marginLeft="space30" display="flex" alignItems="center">
								{toggleState === "desc" ? (
									<Button
										variant="secondary_icon"
										size="reset"
										onClick={() => sortData(hosts, "lastName", "asc")}
									>
										<ChevronUpIcon
											decorative={false}
											size="sizeIcon10"
											title="Ascending"
										/>
									</Button>
								) : (
									<Button
										variant="secondary_icon"
										size="reset"
										onClick={() => sortData(hosts, "lastName", "desc")}
									>
										{toggleState === "none" ? (
											<UnsortedIcon
												decorative={false}
												size="sizeIcon10"
												title="Ascending"
											/> ) : (
											<ChevronDownIcon
												decorative={false}
												size="sizeIcon10"
												title="Descending"
											/>
										)}
									</Button>
								)}
							</Box>
						</Flex></Th>
						<Th><Flex vAlignContent="center">
								Email{" "}
							<Box marginLeft="space30" display="flex" alignItems="center">
								{toggleState === "desc" ? (
									<Button
										variant="secondary_icon"
										size="reset"
										onClick={() => sortData(hosts, "email", "asc")}
									>
										<ChevronUpIcon
											decorative={false}
											size="sizeIcon10"
											title="Ascending"
										/>
									</Button>
								) : (
									<Button
										variant="secondary_icon"
										size="reset"
										onClick={() => sortData(hosts, "email", "desc")}
									>
										{toggleState === "none" ? (
											<UnsortedIcon
												decorative={false}
												size="sizeIcon10"
												title="Ascending"
											/> ) : (
											<ChevronDownIcon
												decorative={false}
												size="sizeIcon10"
												title="Descending"
											/>
										)}
									</Button>
								)}
							</Box>
						</Flex></Th>
						<Th>Role</Th>
						<Th>Skill</Th>
						<Th textAlign="right">Actions</Th>
					</Tr>
				</THead>
				<TBody>
					{hosts && loaded ? (
						hosts?.map((host: HostItem) => (
							<Tr key={host.hostId}>
								<Td scope="row">{host?.firstName ? host?.firstName : "-"}</Td>
								<Td scope="row">{host?.lastName ? host?.lastName : "-"}</Td>
								<Td>{host?.email ? host?.email : "-"}</Td>
								<Td>
									{host?.role ? (
										<Box>
											{
												{
													"Admin": <Badge as="span" variant="new">Admin</Badge>,
													"Host": <Badge as="span" variant="success">Host</Badge>,
													"Speaker": <Badge as="span" variant="warning">Speaker</Badge>,
													"Viewer": <Badge as="span" variant="default">Viewer</Badge>
												}[handleRoleText(host?.role)]
											}
										</Box>
									) : (
										"-"
									)}
								</Td>
								<Td>
									<Box
										display="flex"
										flexDirection="row"
										columnGap="space20"
										flexWrap="wrap"
									>
										{host.skills
											? host.skills &&
											host.skills?.map((skill: string) => (
												<Badge as="span" variant="info" key={skill}>
													{skill && skill}
												</Badge>
											))
											: "-"}
									</Box>
								</Td>
								<Td textAlign="right">
									<Box
										display="flex"
										flexDirection="row"
										justifyContent="flex-end"
										columnGap="space60"
									>
										<Button
											variant="primary"
											onClick={() => {
												navigate(`/hosts/edit/${host.hostId}`);
											}}
										>
											Edit
										</Button>
									</Box>
								</Td>
							</Tr>
						))
					) : (
						<TableSkeletonLoader numberOfTr={1} numberOfTd={6} />
					)}
				</TBody>
			</Table>

			<Box display="flex" justifyContent="center" marginTop="space70">
				<Pagination label="default pagination navigation">
					<PaginationItems>
						<PaginationArrow
							label="Go to previous page"
							variant="back"
							onClick={goToPreviousPage}
							disabled={currentPage === 1}
						/>
						<PaginationLabel>Page {currentPage}</PaginationLabel>
						<PaginationArrow
							label="Go to next page"
							variant="forward"
							onClick={goToNextPage}
							disabled={hosts.length < PAGE_SIZE}
						/>
					</PaginationItems>
				</Pagination>
			</Box>
		</>
	);
};
