import { FC, useEffect, Fragment } from "react";
import { Table, TBody, Th, THead, Tr } from "@twilio-paste/core/table";
import { Box } from "@twilio-paste/core/box";
import { ConferenceItem } from "@mmc/conferencing-booking-client";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { BookingRow } from "./BookingRow";
import { ConferenceRow } from "./ConferenceRow";
import { Filter } from "../Filters/Filters";
import { Separator } from "@twilio-paste/core/separator";
import { ColumnSetting } from "./ColumnSetting";
import { useAdminContext } from "../../hooks/useAdminContext/useAdminContext";
import { BookingPagination } from "./BookingPagination";
import { BookingConferences } from "../../types/sdk";
import { BookingColumn } from "../../hooks/useBookingColumns/useBookingColumns";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { PERMANENT_BOOKING_COLUMNS } from "../../constants";
import { ColumnHeader } from "./ColumnHeader";
import { ExportButton } from "../Buttons/ExportButton/ExportButton";

export const BookingTable: FC = () => {
	const { appState } = useAppState();
	const { getBookings, isLoading, bookings } = useAdminContext();

	useEffect(() => {
		getBookings();
		// TODO: Fix linting issue - adding getBookings to a dependency array causes an infinite loop
		// eslint-disable-next-line react-hooks/exhaustive-deps -- getBookings need to be properly implemented to avoid infinity loops
	}, []);

	return (
		<>
			{/* Bookings Page Count: {bookings.length} */}
			<Filter />
			<Separator orientation={"horizontal"} />
			<Box display="flex" justifyContent="end" alignItems="center">
				<Box display="flex" justifyContent="flex-end" marginY="space60" flexDirection="row" columnGap="space60">
					<ColumnSetting />
					<ExportButton />
				</Box>
			</Box>
			<Box width="100%">
				<Table tableLayout="fixed">
					<THead>
						<Tr>
							{/* Status <PopOverMaker content="Current status of the booking." /> */}
							{[...PERMANENT_BOOKING_COLUMNS, ...appState.columns]
								.filter((v, i, a) => a.findIndex(v2 => (v2.title === v.title)) === i)
								.map((column: BookingColumn) => {
									if (column.value) {
										return <ColumnHeader key={`${column.fieldPath}.${column.name}`} column={column} />;
									} else {
										return null;
									}
								})}
							<Th textAlign="right">Actions</Th>
						</Tr>
					</THead>
					<TBody>
						{bookings && !isLoading ? (
							bookings.map((booking: BookingConferences) => (
								<Fragment key={booking.bookingId}>
									<BookingRow booking={booking} />
									{booking.conferences && booking.conferences.map(
										(conference: ConferenceItem) => (
											<ConferenceRow booking={booking} conference={conference} key={conference.conferenceId} />
										)
									)}
								</Fragment>
							))
						) : (
							<TableSkeletonLoader numberOfTd={6 + appState.columns.filter(x => x.value === true).length} numberOfTr={1} />
						)}
					</TBody>
				</Table>
			</Box>
			<Box display="flex" justifyContent="center" marginTop="space70">
				<BookingPagination />
			</Box>
		</>
	);
};
