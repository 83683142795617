import { Button } from "@twilio-paste/core/dist/button";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { FC } from "react";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { useLocation } from "react-router-dom";

export const ApplyFiltersButton: FC = () => {
	const { getBookings, getConferences, isLoading } = useAdminContext();
	const location = useLocation();

	const onClick = () => {
		if(location.pathname === "/bookings")
		{
			getBookings();
		}

		if(location.pathname === "/calendar")
		{
			getConferences();
		}
	};

	return (<Button variant="primary" onClick={onClick} loading={isLoading}>
		<FilterIcon decorative={false} title="Apply filter" />Apply
	</Button>)
};