import { createContext, FC, useCallback, useState } from "react";
import { ConferenceControlContextType } from "../../types/sdk";
import { RoomApi, Configuration as ConferenceControlClienConfiguration, CiptexRoomStatus } from "@mmc/conferencing-conference-control-client";
import { ReactElementProps } from "../../interface";
import { CONFERENCE_CONTROL_API_URL } from '../../constants';
import { BearerTokenMiddleware } from '@mmc/apiclient-auth-middleware';

export const ConferenceControlContext = createContext<ConferenceControlContextType>(null!);

export const ConferenceControlProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [roomApi, setRoomApi] = useState<RoomApi>();
	const [providerReady, setProviderReady] = useState<boolean>(false);

	const connect = useCallback((token: string) => {
		try {
			const roomApi = new RoomApi(new ConferenceControlClienConfiguration({
				basePath: CONFERENCE_CONTROL_API_URL,
				middleware: [new BearerTokenMiddleware(token)]
			}));
			setRoomApi(roomApi);
			setProviderReady(true);
		} catch (error: any) {
			console.error(error);
		}
	}, []);

	const checkRoomStatus = useCallback(async (conferenceSid: string): Promise<CiptexRoomStatus> => {
		const roomStatus = await roomApi?.fetchRoomStatus({
			roomId: conferenceSid
		});
		if (roomStatus) {
			return roomStatus;
		} else {
			throw new Error("No Room Found");
		}
	}, [roomApi]);


	return <ConferenceControlContext.Provider value={{ providerReady, connect, checkRoomStatus }}>{children}</ConferenceControlContext.Provider>;
}