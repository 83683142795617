import { Pagination, PaginationArrow, PaginationItems, PaginationLabel } from "@twilio-paste/core/dist/pagination";
import { FC, useEffect, useState } from "react";
import { PAGE_SIZE } from "../../constants";
import { useAdminContext } from "../../hooks/useAdminContext/useAdminContext";
import { useAppState } from "../../hooks/useAppState/useAppState";

export const BookingPagination: FC = () => {
	const { appState } = useAppState();
	const { bookings, isLoading, getBookings } = useAdminContext();
	const [ currentPage, setCurrentPage ] = useState<number>(1);
	const [ pageCache, setPageCache ] = useState<{ [key: number ]: string }>({});

	useEffect(() => {
		// Filters Changed so Reset to Page 0;
		setCurrentPage(1);
		setPageCache({});
	}, [ appState.filters ])

	const onNext = () => {
		const key = bookings[ bookings.length - 1 ].bookingId;
		setPageCache({ ...pageCache, [ currentPage + 1 ]: key })
		setCurrentPage(currentPage + 1);
		getBookings(key);
	};

	const onPrevious = () => {
		setCurrentPage(currentPage - 1);
		getBookings(pageCache[currentPage - 1 ]);
	}

	return (<Pagination label="default pagination navigation">
		<PaginationItems>
			<PaginationArrow label="Go to previous page" variant="back" onClick={onPrevious} disabled={currentPage === 1 || isLoading} />
			<PaginationLabel>Page {currentPage}</PaginationLabel>
			<PaginationArrow label="Go to next page" variant="forward" onClick={onNext} disabled={(bookings.length < PAGE_SIZE) || isLoading} />
		</PaginationItems>
	</Pagination>)
};