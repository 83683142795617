import { Region } from "@mmc/conferencing-booking-client";
import { Box } from "@twilio-paste/core/dist/box";
import { Label } from "@twilio-paste/core/dist/label";
import { Select, Option } from "@twilio-paste/core/dist/select";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";

export const RegionFilter: FC = () => {
	const { appState, appDispatch } = useAppState();
	const { isLoading } = useAdminContext();
	const [region, setRegion] = useState<string>(appState.filters.region);

	const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setRegion(e.target.value);
		appDispatch({ type: "set-filters", filters: { ...appState.filters, region: e.target.value as Region } });
	};

	useEffect(() => {
		setRegion(appState.filters.region);
	}, [ appState.filters.region ]);

	return (<Box width="110px">
		<Label htmlFor="regionFilter">Region</Label>
		<Select onChange={onChange} name="regionFilter" id="regionFilter" value={region} disabled={isLoading}>
			<Option key="none" value="">-</Option>
			{Object.entries(Region).map(([ key, value ]) => (
				<Option key={key} value={value}>{value}</Option>
			))}
		</Select>
	</Box>)
}