import { utils, write } from "xlsx";
import { BookingConferences } from "../types/sdk";
import { saveAs } from "file-saver";
import { HostItem } from "@mmc/conferencing-booking-client";
import { secondsToHumanReadable } from "./general";

let _hosts: HostItem[] = [];

const s2ab = (s: any) => {
	const buf = new ArrayBuffer(s.length);
	const view = new Uint8Array(buf);
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
	return buf;
}

const getHostName = (hostId: string): string => {
	const host = _hosts.find(x => x.hostId === hostId);

	if (host) {
		return `${host.firstName} ${host.lastName}`
	} else {
		return ""
	}
};

const getHostNamesFromIds = (hostIds: string[] = []): string[] => {
	return hostIds.map(hostId => getHostName(hostId));
};

const buildConferenceFieldsFromRow = (bookingConference: BookingConferences): { [key: string]: any } => {
	if (!bookingConference.conferences) {
		return {};
	}

	return bookingConference.conferences.reduce((fields, conference, index) => {
		return {
			...fields,
			[`conference${index}_id`]: conference.conferenceId,
			[`conference${index}_name`]: conference.conferenceName,
			[`conference${index}_assignedHostNames`]: getHostNamesFromIds(conference.assignedHosts).join(", "),
			[`conference${index}_roomType`]: conference.conferenceType,
			[`conference${index}_startDateUTC`]: conference.startDate,
			[`conference${index}_endDateUTC`]: conference.endDate,
			[`conference${index}_startDateLocal`]: conference.startDate ? new Date(conference.startDate).toLocaleString() : "",
			[`conference${index}_endDateLocal`]: conference.endDate ? new Date(conference.endDate).toLocaleString() : "",
			[`conference${index}_status`]: conference.status
		};
	}, {});
};

export const buildRows = (data: BookingConferences[]) => {
	return data.map(row => ({
		accountSid: row.accountSid,
		bookingId: row.bookingId,
		eventId: row.event.id,
		eventUid: row.event.uid,
		eventName: row.event.name,
		eventUsage: row.event.usage,
		eventStartDateUTC: row.event.startDate,
		eventStartDateLocal: row.event.startDate ? new Date(row.event.startDate).toLocaleString() : "",
		eventDuration: secondsToHumanReadable(row.event.duration),
		eventServiceLevel: row.event.serviceLevel,
		eventisHighAlert: row.event.isHighAlert,
		eventUrl: row.event.url,
		accountId: row.account?.id,
		accountName: row.account?.name,
		accountType: row.account?.type,
		accountRegion: row.account?.region,
		status: row.status,
		brandingMohUrls: row.branding?.mohUrls,
		brandingVirtualBackgroundUrls: row.branding?.virtualBackgroundUrls,
		brandingLobbyImgUrl: row.branding?.lobbyImgUrl,
		brandingLogoUrl: row.branding?.logoUrl,
		assignedHosts: getHostNamesFromIds(row.assignedHosts).join(", "),
		dialInGroups: row?.dialInGroups?.toString(),
		optionsHasQaSession: row.options?.hasQaSession,
		optionsHasVideo: row.options?.hasVideo,
		optionsLanguage: row.options?.language,
		leaderFirstName: row.leader?.firstName,
		leaderLastName: row.leader?.lastName,
		leaderEmail: row.leader?.email,
		leaderPhone: row.leader?.phone,
		leaderCompany: row.leader?.company,
		organizerFirstName: row.organizer?.firstName,
		organizerLastName: row.organizer?.lastName,
		organizerEmail: row.organizer?.email,
		organizerPhone: row.organizer?.phone,
		organizerCompany: row.organizer?.company,
		notes: row.notes,
		callbacksBookingCallbackUrl: row.callbacks.bookingCallbackUrl,
		callbacksReportingUrl: row.callbacks.reportingUrl,
		...buildConferenceFieldsFromRow(row)
	}));
}

export const DownloadXLSX = (data: BookingConferences[], hosts: HostItem[]) => {
	_hosts = hosts;

	const rows = buildRows(data);
	const sheetName = "notified_bookings";
	const wb = utils.book_new();

	wb.Props = {
		Title: "Notified Conferences",
		Subject: "Notified Conferences Subject",
		CreatedDate: new Date()
	};
	wb.SheetNames.push(sheetName);

	const ws = utils.json_to_sheet(rows);

	wb.Sheets[sheetName] = ws;

	const wbout = write(wb, { bookType: "xlsx", bookSST: false, type: "binary" });

	saveAs(new Blob([s2ab(wbout)], { type: "" }), "notified_bookings.xlsx");
};

