import { FC, useEffect, useState } from "react";
import { Table, TBody, Th, THead, Tr, Td } from "@twilio-paste/core/table";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { ListSkillSet, SkillSetItem } from "@mmc/conferencing-booking-client";
import { useNavigate } from "react-router-dom";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { ChevronUpIcon } from "@twilio-paste/icons/esm/ChevronUpIcon";
import { UnsortedIcon } from "@twilio-paste/icons/esm/UnsortedIcon";
import { Flex } from "@twilio-paste/flex";

export const SkillList: FC = () => {
	const [skillSet, setSkillSet] = useState<SkillSetItem[]>([]);
	const [loaded, setLoaded] = useState(false);
	const navigate = useNavigate();
	const { listSkillSets } = useBookingContext();
	const [toggleState, setToggleState] = useState<string>("none");

	const sortData = (data: SkillSetItem[], sortBy: string, sortOrder: string) => {
		const sortedItems = data.sort((a: any, b: any) => {
			if (sortBy === "name") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return b.name.localeCompare(a.name);
				} else {
					setToggleState("desc");
					return a.name.localeCompare(b.name);
				}
			}
			return 0;
		});
		return setSkillSet(() => [...sortedItems]);
	}


	useEffect(() => {
		(async () => {
			try {
				const data: ListSkillSet = await listSkillSets();
				setSkillSet(data.skillsets);
				setLoaded(true);
			} catch (error) {
				console.error(error);
			}
		})();
	}, [listSkillSets]);

	return (
		<>
			<Table>
				<THead>
					<Tr>
						<Th><Flex vAlignContent="center">
								Skill name
							<Box marginLeft="space30" display="flex" alignItems="center">
								{toggleState === "desc" ? (
									<Button
										variant="secondary_icon"
										size="reset"
										onClick={() => sortData(skillSet, "name", "asc")}
									>
										<ChevronUpIcon
											decorative={false}
											size="sizeIcon10"
											title="Ascending"
										/>
									</Button>
								) : (
									<Button
										variant="secondary_icon"
										size="reset"
										onClick={() => sortData(skillSet, "name", "desc")}
									>
										{toggleState === "none" ? (
											<UnsortedIcon
												decorative={false}
												size="sizeIcon10"
												title="Ascending"
											/> ) : (
											<ChevronDownIcon
												decorative={false}
												size="sizeIcon10"
												title="Descending"
											/>
										)}
									</Button>
								)}
							</Box>
						</Flex></Th>
						<Th textAlign="right">Actions</Th>
					</Tr>
				</THead>
				<TBody>
					{skillSet && loaded ? (
						skillSet &&
						skillSet.map((skill: SkillSetItem) => (
							<Tr key={skill.skillSetId}>
								<Td>{skill.name}</Td>
								<Td textAlign="right">
									<Box display="flex" flexDirection="row" justifyContent="flex-end" columnGap="space60">
										<Button variant="primary" onClick={() => { navigate(`/skills/edit/${skill.skillSetId}`); }}>
											Edit the Skills Set
										</Button>
									</Box>
								</Td>
							</Tr>
						))) : (<TableSkeletonLoader numberOfTr={1} numberOfTd={2} />)}
				</TBody>
			</Table>
		</>
	);
};
