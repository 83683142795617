import { useContext } from "react";
import { VideoContext } from "../../components/VideoProvider/VideoProvider";

import { VideoContextType } from "../../types/sdk";

export const useVideoContext = (): VideoContextType => {
	const context = useContext(VideoContext);
	if (!context) {
		throw new Error("useVideoContext must be used within a VideoProvider");
	}
	return context;
}