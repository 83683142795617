import { Button } from "@twilio-paste/core/dist/button";
import { FC } from "react";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";

export const ExportButton: FC = () => {
	const { isLoading, downloadBookingsAsXlsx } = useAdminContext();

	const onClick = () => {
		downloadBookingsAsXlsx();
	};

	return (<Button variant="secondary" onClick={onClick} disabled={isLoading} ><DownloadIcon decorative={true} /> Export to CSV</Button>)
};
