import { FC, useState } from "react";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/button";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { useConferenceControlContext } from "../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { CiptexRoomStatus } from "@mmc/conferencing-conference-control-client";
import { HelpText, Separator } from "@twilio-paste/core";
import { TWILIO_INSIGHTS_API_BASE_URL } from '../../constants';

export const RoomInsights: FC = () => {
	const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
	const [confSid, setConfSid] = useState<string>("");
	const { checkRoomStatus } = useConferenceControlContext();
	const { toaster } = useToasterContext();
	const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		setConfSid(target.value);
	};

	const Submit = async (event: any) => {
		event.preventDefault();

		setSubmissionLoading(true);
		try {
			const content: CiptexRoomStatus = await checkRoomStatus(confSid);
			window.open(`${TWILIO_INSIGHTS_API_BASE_URL}video/video-rooms?frameUrl=/console/video/logs/rooms/${content.roomId}`, "_blank");
			setSubmissionLoading(false);
		} catch (e) {
			toaster.push({
				message: "The room does not exist. Please make sure you are using the correct environment (e.g. Dev, QA, Prod) for the corresponding room ID.",
				variant: "error",
				dismissAfter: 4000
			});
			setSubmissionLoading(false);
		}
	};

	return (
		<form onSubmit={Submit}>
			<Box
				marginY="space60"
				display="flex"
				justifyContent="start"
				flexDirection="row"
				columnGap="space60"
				alignItems="end"
			>
				<Box width="size70">
					<Label required htmlFor="conferenceSid">Conference SID</Label>
					<Input
						id="conferenceSid"
						name="conferenceSid"
						type="text"
						placeholder="CIxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
						onChange={onChange}
						required
					/>
					<HelpText>Please note room associated with the requested resource cannot be older than 7 days</HelpText>
				</Box>
			</Box>
			<Separator orientation="horizontal" verticalSpacing="space120" />
			<Box justifyContent="end" display="flex">
				<Button type="submit" variant="primary" loading={submissionLoading}>
					Open Twilio Console
				</Button>
			</Box>
		</form>
	);
};