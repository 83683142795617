import { StrictMode } from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import { ThemeProvider } from "./components/ThemeProvider/ThemeProvider";
import { ReportWebVitals } from "./core/ReportWebVitals";
import { Router } from "./core/Router";

// Use createRoot API to render the application
const rootElement = document.querySelector("#root");
const root = createRoot(rootElement ? rootElement : document.createDocumentFragment());
root.render(
	<StrictMode>
		<ThemeProvider>
			<Router />
		</ThemeProvider>
	</StrictMode>
);

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
// eslint-disable-next-line no-console
ReportWebVitals(console.log);
