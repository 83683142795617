import { FC } from "react";
import { Popover, PopoverContainer, PopoverButton } from "@twilio-paste/core/popover";
import { InformationIcon } from "@twilio-paste/icons/esm/InformationIcon";
import { Box } from "@twilio-paste/core/box";

interface PopOverType {
  content: string;
}

export const PopOverMaker: FC<PopOverType> = ({ content }) => {
	return (
		<Box marginLeft="space30" display="flex">
			<PopoverContainer baseId="popover-example">
				<PopoverButton variant="reset">
					<InformationIcon decorative={false} title="Information" />
				</PopoverButton>
				<Popover aria-label="Popover">
					{content}
				</Popover>
			</PopoverContainer>
		</Box>
	);
};
