import { FC } from "react";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { SideNav } from "../SideNav/SideNav";
import { Header } from "../Header/Header";
import { Box } from "@twilio-paste/core/dist/box";
import { Outlet } from "react-router-dom";
import { BreadcrumbMaker } from "../BreadcrumbMaker/BreadcrumbMaker";
import { HEADERS_HEIGHT } from "../../constants";
import { ToggleNavButton } from "../Buttons/ToggleNavButton/ToggleNavButton";
import { useAppState } from "../../hooks/useAppState/useAppState";

export const AdminContainer: FC = () => {
	const { appState } = useAppState();

	return (<PrivateRoute>
		<>
			{/* Header Comp */}
			<Header />
			<Box display="flex">
				{/* Side Nav Comp */}
				{ appState.isNavOpen ? <SideNav /> : null }
				{/* Main Body Content */}
				<Box as="main" padding="space100" paddingY="space80" width={[`calc(100vw - ${HEADERS_HEIGHT})`, "100%", "100%"]}>
					<Box marginBottom="space60">
						<ToggleNavButton />
					</Box>
					{/* Breadcrumb */}
					<BreadcrumbMaker />
					<Outlet />
				</Box>
			</Box>
		</>
	</PrivateRoute>)
};