import { FC, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AddHost } from "../components/AddHost/AddHost";
import { AdminContainer } from "../components/AdminContainer/AdminContainer";
import { AdminProvider } from "../components/AdminProvider/AdminProvider";
import { AppStateProvider } from "../components/AppStateProvider/AppStateProvider"; // Add this line
import { AssignHostTable } from "../components/AssignHostTable/AssignHostTable";
import { BookingTable } from "../components/BookingTable/BookingTable";
import { Calendar } from "../components/Calendar/Calendar";
import { ConferenceControlProvider } from "../components/ConferenceControlProvider/ConferenceControlProvider";
import { EditHost } from "../components/EditHost/EditHost";
import { EditSkill } from "../components/EditSkill/EditSkill";
import { HostsList } from "../components/HostsList/HostsList";

import { Loading } from "../components/Loading/Loading";
import { LoginPage } from "../components/LoginPage/LoginPage";
import { BookingProvider } from "../components/BookingProvider/BookingProvider";
import { ResetPasswordPage } from "../components/ResetPasswordPage/ResetPasswordPage";
import { RoomInsights } from "../components/RoomInsights/RoomInsights";
import { SkillList } from "../components/SkillList/SkillList";
import { ToasterProvider } from "../components/ToasterProvider/ToasterProvider";
import { VideoComposition } from "../components/VideoComposition/VideoComposition";
import { VideoProvider } from "../components/VideoProvider/VideoProvider";

export const Router: FC = () => {
	return (<Suspense fallback={<Loading />}>
		<BrowserRouter basename="/">
			<AppStateProvider>
					<BookingProvider>
						<ConferenceControlProvider>
							<VideoProvider>
								<ToasterProvider>
									<AdminProvider>

										<Routes>
											<Route index element={<LoginPage />} />
											<Route path="/password/reset" element={<ResetPasswordPage />} />
											<Route path="/" element={<AdminContainer />}>
												<Route path="bookings" element={<BookingTable />} />
												<Route path="bookings/:bookingId" element={<AssignHostTable />} />
												<Route path="calendar" element={<Calendar />} />
												<Route path="hosts" element={<HostsList />} />
												<Route path="hosts/add" element={<AddHost />} />
												<Route path="hosts/edit" element={<HostsList />} />
												<Route path="hosts/edit/:hostId" element={<EditHost />} />
												<Route path="skills" element={<SkillList />} />
												<Route path="skills/edit" element={<SkillList />} />
												<Route path="skills/edit/:skillSetId" element={<EditSkill />} />
												<Route path="composition" element={<VideoComposition />} />
												<Route path="insights" element={<RoomInsights />} />
											</Route>
										</Routes>

									</AdminProvider>
								</ToasterProvider>
							</VideoProvider>
						</ConferenceControlProvider>
					</BookingProvider>
			</AppStateProvider>
		</BrowserRouter>
	</Suspense>);
};