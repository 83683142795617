/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useState } from "react";
import { Configuration as BookingClientConfiguration, HostAuthApi, Token } from "@mmc/conferencing-booking-client";
import { ACCOUNT_SID, BOOKING_API_URL } from "../../constants";

const hostAuthApi = new HostAuthApi(new BookingClientConfiguration({
	basePath: BOOKING_API_URL
}));

const getErrorMessage = (message: string): string => {
	switch (message) {
	case "passcode incorrect":
		return "Passcode is incorrect";
	case "passcode expired":
		return "Passcode has expired";
	default:
		return message;
	}
}

export const useAuth = () => {
	const [token, setToken] = useState<Token | null>(null);
	const [isAuthReady, setIsAuthReady] = useState<boolean>(false);

	const authHost = useCallback(async (email: string, password: string) => {
		if (email && password) {
			const token: Token = await hostAuthApi.tokenAccountSidPost({
				accountSid: ACCOUNT_SID,
				loginDetail: {
					email: email,
					password: password
				}
			});
			setToken(token);
			setIsAuthReady(true);
		} else {
			throw new Error("NO HOST ID???");
		}
	}, []);

	const setNewPassword = async (hostId: string, { resetToken, newPassword }: { resetToken: string; newPassword: string }): Promise<void> => {
		await hostAuthApi.hostHostIdPasswordUpdatePut({
			hostId: hostId,
			setPasswordDetail: {
				accountSid: ACCOUNT_SID,
				resetToken: resetToken,
				newPassword: newPassword
			}
		});
	};

	const signOut = useCallback((): Promise<void> => {
		setToken(null);
		return Promise.resolve();
	}, []);

	return { token, isAuthReady, authHost, setNewPassword, signOut };
}