import { Flex } from "@twilio-paste/core/dist/flex"
import { Th } from "@twilio-paste/core/dist/table"
import { FC } from "react"
import { BookingColumn } from "../../hooks/useBookingColumns/useBookingColumns"
import { PopOverMaker } from "../PopOverMaker/PopOverMaker"

interface ColumnHeaderProps {
	column: BookingColumn
}

export const ColumnHeader: FC<ColumnHeaderProps> = ({ column }) => {
	return (
		<Th textAlign="center">
			<Flex vAlignContent="center" hAlignContent="center">
				{column.title}
				{column.name === "mediaRegion" ? <PopOverMaker content="Media region is the location of the Server where media processing takes place. This is a conference-level property." /> : null}
				{column.name === "regions" ? <PopOverMaker content="Geographical region of the account." /> : null}
			</Flex>
		</Th>);
}