import { useContext } from "react";
import { BookingContext } from '../../components/BookingProvider/BookingProvider';
import { BookingContextType } from "../../types/sdk";

export const useBookingContext = (): BookingContextType => {
	const context = useContext(BookingContext);
	if (!context) {
		throw new Error("useBookingContext must be used within a BookingProvider");
	}
	return context;
}
