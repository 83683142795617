import { FC, useEffect, useState } from "react";
import { Table, TBody, Th, THead, Tr, Td } from "@twilio-paste/core/table";
import { Box } from "@twilio-paste/core/box";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Button } from "@twilio-paste/core/button";
import { useNavigate, useParams } from "react-router-dom";
import { Booking, HostItem } from "@mmc/conferencing-booking-client";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Badge } from "@twilio-paste/core/badge";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { Flex } from "@twilio-paste/core";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { ChevronUpIcon } from "@twilio-paste/icons/esm/ChevronUpIcon";
import { UnsortedIcon } from "@twilio-paste/icons/esm/UnsortedIcon";
import { useAdminContext } from "../../hooks/useAdminContext/useAdminContext";

export const AssignHostTable: FC = () => {
	const { bookingId } = useParams();
	const { hosts: listHost } = useAdminContext();
	const [ hosts, setHosts ] = useState<HostItem[]>([]);
	const [booking, setBooking] = useState<Booking>();
	const [searchValue, setSearchValue] = useState("");
	const [loading, setLoading] = useState<boolean>(false);
	const [bookingLoading, setBookingLoading] = useState<boolean>(false);
	const [toggleState, setToggleState] = useState<string>("none");
	const navigate = useNavigate();
	const { toaster } = useToasterContext();
	const { getBooking, updateBooking } = useBookingContext();

	useEffect(() => {
		setHosts(listHost);
	}, [ listHost ]);

	useEffect(() => {
		setHosts(listHost);
		(async () => {
			if (!bookingId) {
				navigate("/bookings");
				return;
			}

			setBookingLoading(true);
			try {
				const data: Booking = await getBooking(bookingId);
				setBooking(data);
				setBookingLoading(false);
			} catch (error) {
				setBookingLoading(false);
			}
		})();
	}, [bookingId, getBooking, listHost, navigate]);

	const sortData = (data: HostItem[], sortBy: string, sortOrder: string) => {
		const sortedItems = data.sort((a: any, b: any) => {
			if (sortBy === "firstName") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return b.firstName.localeCompare(a.firstName);
				} else {
					setToggleState("desc");
					return a.firstName.localeCompare(b.firstName);
				}
			} else if (sortBy === "lastName") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return a.lastName.localeCompare(b.lastName);
				} else {
					setToggleState("desc");
					return b.lastName.localeCompare(a.lastName);
				}
			} else if (sortBy === "email") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return b.email.localeCompare(a.email);
				} else {
					setToggleState("desc");
					return a.email.localeCompare(b.email);
				}
			} else if (sortBy === "skill") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return a.skill.localeCompare(b.skill);
				} else {
					setToggleState("desc");
					return b.skill.localeCompare(a.skill);
				}
			}
			return 0;
		});
		return setHosts(() => [...sortedItems]);
	}

	const handleAssignHost = async (hostInfo: HostItem) => {
		if (!booking) {
			return;
		}

		setLoading(true);
		try {
			const data = await updateBooking(booking.bookingId, {
				assignedHosts: booking.assignedHosts ? [...booking.assignedHosts, hostInfo.hostId] : [hostInfo.hostId]
			} as any);
			setBooking(data);
			setLoading(false);
			toaster.push({
				message: "Host assigned successfully",
				variant: "success",
				dismissAfter: 4000
			});
		} catch (error: any) {
			setLoading(false);
			toaster.push({
				message: "Host could not be assigned",
				variant: "error",
				dismissAfter: 4000
			});
		}
	};

	const handleUnassignHost = async (hostInfo: HostItem) => {
		if (!booking || !booking.assignedHosts) {
			return;
		}
		setLoading(true);
		try {
			const data = await updateBooking(booking.bookingId, {
				assignedHosts: booking.assignedHosts.filter(host => host !== hostInfo.hostId)
			} as any);
			setBooking(data);
			setLoading(false);
			toaster.push({
				message: "Host unassigned successfully",
				variant: "success",
				dismissAfter: 4000
			});
		} catch (error: any) {
			setLoading(false);
			toaster.push({
				message: "Host could not be unassigned",
				variant: "error",
				dismissAfter: 4000
			});
		}
	};

	const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(target.value.toLowerCase().trim());
	};

	return (
		<>
			<Box marginY="space60" display="flex" justifyContent="space-between" flexDirection="row" columnGap="space60" alignItems="end">
				<Box></Box>
				<Box minWidth="400px">
					<Label htmlFor="search">Email of the Host</Label>
					<Input id="search" name="search" type="text" placeholder="john.doe@domain.com" onChange={onChange} value={searchValue.trim()} />
				</Box>
			</Box>

			<Table>
				<THead>
					<Tr>
						<Th><Flex vAlignContent="center">
								Host name{" "}
							<Box marginLeft="space30" display="flex" alignItems="center">
								{toggleState === "desc" ? (
									<Button variant="secondary_icon" size="reset" onClick={() => sortData(hosts, "firstName", "asc")}>
										<ChevronUpIcon decorative={false} size="sizeIcon10" title="Ascending" />
									</Button>
								) : (
									<Button variant="secondary_icon" size="reset" onClick={() => sortData(hosts, "firstName", "desc")}>
										{toggleState === "none" ? (
											<UnsortedIcon decorative={false} size="sizeIcon10" title="Ascending" /> )
											: (<ChevronDownIcon decorative={false} size="sizeIcon10" title="Descending" />)}
									</Button>
								)}
							</Box>
						</Flex></Th>
						<Th><Flex vAlignContent="center">
								Email{" "}
							<Box marginLeft="space30" display="flex" alignItems="center">
								{toggleState === "desc" ? (
									<Button variant="secondary_icon" size="reset" onClick={() => sortData(hosts, "email", "asc")}>
										<ChevronUpIcon decorative={false} size="sizeIcon10" title="Ascending" />
									</Button>
								) : (
									<Button variant="secondary_icon" size="reset" onClick={() => sortData(hosts, "email", "desc")}>
										{toggleState === "none" ? (<UnsortedIcon decorative={false} size="sizeIcon10" title="Ascending" /> ) : (
											<ChevronDownIcon decorative={false} size="sizeIcon10" title="Descending" />
										)}
									</Button>
								)}
							</Box>
						</Flex></Th>
						<Th>Skills</Th>
						<Th textAlign="right">Actions</Th>
					</Tr>
				</THead>
				<TBody>
					{hosts ? (
						hosts.filter(x => x.email.toLowerCase().includes(searchValue)).map((host: HostItem, index: number) => (
							<Tr key={index}>
								<Td>
									{host.firstName} {host.lastName}
								</Td>
								<Td>{host.email}</Td>
								<Td>
									<Box display="flex" flexDirection="row" columnGap="space20" flexWrap="wrap">
										{host.skills ? (host.skills.map((skill: string) => (
											<Badge as="span" variant="info" key={skill}>
												{skill && skill}
											</Badge>))
										) : ("-")}
									</Box>
								</Td>

								<Td textAlign="right">
									{!bookingLoading ? (
										booking?.assignedHosts && booking.assignedHosts.includes(host.hostId) ?
											<Button variant="secondary" onClick={() => { handleUnassignHost(host); }} loading={loading}>Unassign</Button> :
											<Button variant="primary" onClick={() => { handleAssignHost(host); }} loading={loading}>Assign</Button>
									) : (
										<Box display="flex" justifyContent="end" >
											<SkeletonLoader width="90px" height="36px" />
										</Box>
									)}
								</Td>
							</Tr>
						))
					) : (<TableSkeletonLoader numberOfTr={1} numberOfTd={4} />)}
				</TBody>
			</Table>
		</>
	);
};
