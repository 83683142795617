import { FC } from "react";
import { Tr, Td } from "@twilio-paste/core/table";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";

interface TableType {
  numberOfTr: number;
  numberOfTd: number;
}

export const TableSkeletonLoader: FC<TableType> = ({ numberOfTr, numberOfTd }) => {
	return (
		<>
			{[...Array(numberOfTr)].map(() => (
				<Tr key={Math.random()}>
					{[...Array(numberOfTd)].map(() => (
						<Td key={Math.random()}>
							<SkeletonLoader width="100%" />
						</Td>
					))}
				</Tr>
			))}
		</>
	);
};
