import { useAdminContext } from "../useAdminContext/useAdminContext";
export const useHostList = () => {
	const { hosts } = useAdminContext();

	const getHostName = (hostId: string): string => {
		const host = hosts.find(x => x.hostId === hostId);
		if(host)
		{
			return `${host.firstName} ${host.lastName}`
		}
		else
		{
			return ""
		}
	};

	return { getHostName };
};