import { FC, useState } from "react";
import { useUID } from "@twilio-paste/core/uid-library";
import { Button } from "@twilio-paste/core/button";
import { Modal, ModalBody, ModalHeader, ModalHeading } from "@twilio-paste/core/modal";
import { BuiltInIcon } from "@twilio-paste/icons/esm/BuiltInIcon";
import { Grid, Column } from "@twilio-paste/core/grid";
import { Heading } from "@twilio-paste/core/heading";
import { UnorderedList, ListItem } from "@twilio-paste/core/list";
import { Checkbox, CheckboxGroup } from "@twilio-paste/core/checkbox";
import { Badge, Box } from "@twilio-paste/core";
import { BookingColumn, useBookingColumns } from "../../hooks/useBookingColumns/useBookingColumns";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useAdminContext } from "../../hooks/useAdminContext/useAdminContext";

export const ColumnSetting: FC = () => {
	const { appState } = useAppState();
	const [isOpen, setIsOpen] = useState(false);
	const { isLoading } = useAdminContext();
	const { setColumns } = useBookingColumns();
	const [ columnState, setColumnState ] = useState<BookingColumn[]>(appState.columns);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => {
		setColumns(columnState);
		setIsOpen(false);
	};
	const modalHeadingID = useUID();

	const dedupedColumns = columnState.filter(column => column.name !== "conferenceId");

	const onChange = (e: any, index: number) => {
		const affectedColumn = dedupedColumns[index];

		// Update all columns where the label is the same
		// This is because we can have multiple columns with the same name that show different data (e.g. "Booking / Conference ID" that show different fields depending on the data).
		// We hide all but one of these columns in the "Configure Columns" modal, but changes to one should apply to all with the same name.
		const tempState = [ ...columnState ].map(column => {
			if (column.title !== affectedColumn.title) {
				return column;
			}

			return {
				...column,
				value: e.target.checked
			}
		});

		setColumnState(tempState);
	}

	return (
		<>
			<Button variant="secondary" onClick={handleOpen} disabled={isLoading}>
				<BuiltInIcon decorative={true} /> Configure Columns
			</Button>

			<Modal ariaLabelledby={modalHeadingID} isOpen={isOpen} onDismiss={handleClose} size="wide">
				<ModalHeader>
					<ModalHeading as="h3" id={modalHeadingID}>
						Configure Columns
					</ModalHeading>
				</ModalHeader>
				<ModalBody>
					<Grid gutter="space60">
						<Column>
							<Heading as="h4" variant="heading40">
								Selectable columns:
							</Heading>
							<CheckboxGroup name="columns" legend="">
								{dedupedColumns.map(({ name, value, title }: BookingColumn, index: number) => (
									<Checkbox
										key={name}
										id={name}
										name={name}
										onClick={(e) => onChange(e, index)}
										defaultChecked={value}
									>
										{title}
									</Checkbox>
								))}
							</CheckboxGroup>
						</Column>
						<Column>
							<Heading as="h4" variant="heading40">
								Always enabled:
							</Heading>
							<UnorderedList>
								<ListItem>Account Name</ListItem>
								<ListItem>Booking/Conf Name</ListItem>
								<ListItem><Box display="flex" columnGap="space30">Assigned Hosts <Badge variant="new" as="span">Admin Only</Badge></Box></ListItem>
								<ListItem>Room Type</ListItem>
								<ListItem>Date/Time</ListItem>
								<ListItem>Status</ListItem>
								<ListItem>Actions</ListItem>
							</UnorderedList>
						</Column>
					</Grid>
				</ModalBody>
			</Modal>
		</>
	);
};
