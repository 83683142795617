import { Alert } from "@twilio-paste/alert";
import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/text";
import { Heading } from "@twilio-paste/heading";
import { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useHeight } from "../../hooks/useHeight/useHeight";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { HelpText } from "@twilio-paste/help-text";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Button } from "@twilio-paste/button";

export const ResetPasswordPage: FC = () => {
	const height = useHeight();
	const navigate = useNavigate();
	const [ searchParams ] = useSearchParams();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { setNewPassword } = useAppState();
	const [alert, setAlert] = useState<boolean>(false);
	const [ ids, setIds ] = useState<{resetId: string, hostId: string}>();
	const [form, setForm] = useState<{ password: string, password2: string }>({
		password: "",
		password2: ""
	});

	useEffect(() => {
		const r = searchParams.get("r");
		const h = searchParams.get("h");
		if(!r || r === null || /^RI[0-9a-fA-F]{32}$/.test(r) === null || !h || h === null || /^HI[0-9a-fA-F]{32}$/.test(h) === null)
		{
			navigate("/", { replace: true });
			return;
		}
		setIds({ resetId: r, hostId: h });
	}, [searchParams, navigate]);

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setAlert(false);


		if(!ids?.hostId || !ids.resetId)
		{
			return;
		}

		if(form.password !== form.password2)
		{
			setAlert(true);
			setIsLoading(false);
		}

		try {
			setIsLoading(true);
			await setNewPassword(ids.hostId, { resetToken: ids.resetId, newPassword: form.password } )
			setIsLoading(false);
			navigate("/", { replace: true });
		} catch (error) {
			setIsLoading(false);
			setAlert(true);
		}
	};

	const onChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [name]: value });
	};

	return (<Box height={height} display="flex" flexDirection="column" flex={1} alignContent="center" justifyContent="center" alignItems="center" justifyItems="center">
		<Box borderColor="colorBorder" borderWidth="borderWidth10" borderStyle="solid" width={["100%", "30rem", "30rem"]} borderRadius="borderRadius30" padding="space100">
			<form onSubmit={onSubmit}>
				{alert && <Box marginY="space30">
					<Alert variant="error">
						<Text as="span"><strong>Wrong email or password.</strong> To reset your password contact your IT Team</Text>
					</Alert>
				</Box>}
				<Heading as="h2" variant="heading10">Set New Password</Heading>
				<Box marginBottom="space60">
					<Label htmlFor="password" required>New Password</Label>
					<Input
						aria-describedby="passsword_help_text"
						id="password"
						name="password"
						onChange={onChange}
						value={form.password}
						type="password"
						autoComplete="new-password"
						placeholder="new password"
						required
					/>
					<HelpText id="password_help_text">Please enter a New Password</HelpText>
				</Box>
				<Box marginBottom="space60">
					<Label htmlFor="password2" required>Confirm New Password</Label>
					<Input
						aria-describedby="passsword2_help_text"
						id="password2"
						name="password2"
						onChange={onChange}
						value={form.password2}
						type="password"
						placeholder="confirm new password"
						required
					/>
					<HelpText id="password2_help_text">Please confirm New Password</HelpText>
				</Box>
				<Box marginTop="space60" float="right">
					<Button variant="primary" type="submit" loading={isLoading}>Reset Password</Button>
				</Box>
			</form>
		</Box>
	</Box>)
};