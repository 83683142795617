import { useContext } from "react";
import { ToasterContext } from "../../components/ToasterProvider/ToasterProvider";
import { ToasterContextType } from "../../types/sdk";

export const useToasterContext = (): ToasterContextType => {
	const context = useContext(ToasterContext);
	if (!context) {
		throw new Error("useToasterContext must be used within a ToasterProvider");
	}
	return context;
}