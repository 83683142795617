export enum SearchType {
	Unknown,
	MMCId,
	BookingId,
	ConferenceId
}

const BOOKING_ID_PREFIX = "BI";
const CONFERENCE_ID_PREFIX = "CI";
const BOOKING_ID_LENGTH = 34;
const CONFERENCE_ID_LENGTH = 34;
const MMC_ID_LENGTH = 8;

export const getSearchTypeFromQuery = (searchQuery?: string): SearchType => {
	if (!searchQuery) {
		return SearchType.Unknown;
	}

	const { length } = searchQuery;
	const uppercaseQuery = searchQuery.toUpperCase();

	if (length === MMC_ID_LENGTH) {
		return SearchType.MMCId;
	}

	if (length === BOOKING_ID_LENGTH && uppercaseQuery.startsWith(BOOKING_ID_PREFIX)) {
		return SearchType.BookingId;
	}

	if (length === CONFERENCE_ID_LENGTH && uppercaseQuery.startsWith(CONFERENCE_ID_PREFIX)) {
		return SearchType.ConferenceId;
	}

	return SearchType.Unknown;
};
