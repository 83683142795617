import React, { FC, useState, FormEvent, useEffect } from "react";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Box } from "@twilio-paste/core/box";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { HelpText } from "@twilio-paste/core/help-text";
import { Heading } from "@twilio-paste/core/heading";
import { Button } from "@twilio-paste/core/button";
import { Alert } from "@twilio-paste/core/alert";
import { Text } from "@twilio-paste/core/text";
import { LoginDetail } from "@mmc/conferencing-booking-client";
import { useHeight } from "../../hooks/useHeight/useHeight";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useConferenceControlContext } from "../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";

export const LoginPage: FC = () => {
	const height = useHeight();
	const navigate = useNavigate();
	const [ searchParams ] = useSearchParams();
	const { authHost, token, appDispatch, appState } = useAppState();
	const { connect: notifiedConnect, providerReady } = useBookingContext();
	const { connect: conferenceControlClient } = useConferenceControlContext();
	const { connect: videoClient } = useVideoContext();
	const [form, setForm] = useState<LoginDetail>({
		email: "",
		password: ""
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [alert, setAlert] = useState<boolean>(false);

	useEffect(() => {
		if(providerReady && appState.token && appState.token !== "" && appState.token !== null  )
		{
			navigate(searchParams.get("redirect") || "bookings", { replace: true });
		}
	}, [searchParams, providerReady, appState.token, navigate]);

	useEffect(() => {
		if (token) {
			appDispatch({ type: "set-token", token: token.token });
			appDispatch({ type: "set-role", role: token.role });
			appDispatch({ type: "set-host-id", hostId: token.hostId });
			notifiedConnect(token.token);
			conferenceControlClient(token.token);
			videoClient(token.token);
		}
	}, [appDispatch, conferenceControlClient, notifiedConnect, videoClient, token]);

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setAlert(false);
		setIsLoading(true);

		try {
			await authHost(form.email, form.password);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			setAlert(true);
		}
	};

	const onChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [name]: value });
	};

	return (
		<Box height={height} display="flex" flexDirection="column" flex={1} alignContent="center" justifyContent="center" alignItems="center" justifyItems="center">
			<Box borderColor="colorBorder" borderWidth="borderWidth10" borderStyle="solid" width={["100%", "30rem", "30rem"]} borderRadius="borderRadius30" padding="space100">
				<form onSubmit={onSubmit}>
					{alert && <Box marginY="space30">
						<Alert variant="error">
							<Text as="span"><strong>Wrong email or password.</strong> To reset your password contact your IT Team</Text>
						</Alert>
					</Box>}
					<Heading as="h2" variant="heading10">Login</Heading>
					<Box marginBottom="space60">
						<Label htmlFor="email" required>Email</Label>
						<Input
							aria-describedby="email_help_text"
							id="email"
							name="email"
							autoComplete="email"
							onChange={onChange}
							value={form.email}
							type="text"
							placeholder="email"
							required
						/>
						<HelpText id="email_help_text">Please enter a valid email.</HelpText>
					</Box>
					<Box>
						<Label htmlFor="password" required>Password</Label>
						<Input
							aria-describedby="password_help_text"
							id="password"
							name="password"
							onChange={onChange}
							value={form.password}
							autoComplete="current-password"
							type="password"
							required
						/>
						<HelpText id="password_help_text">Please enter a valid password.</HelpText>
					</Box>
					<Box marginTop="space60" float="right">
						<Button variant="primary" type="submit" loading={isLoading}>Login</Button>
					</Box>
				</form>
			</Box>
		</Box>);
};

