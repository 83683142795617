import { FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { ConferenceTypeFilter } from "./ConferenceTypeFilter/ConferenceTypeFilter";
import { HostFilter } from "./HostFilter/HostFilter";
import { RegionFilter } from "./RegionFilter/RegionFilter";
import { StatusFilter } from "./StatusFilter/StatusFilter";
import { DateTimeFilter } from "./DateTimeFilter/DateTimeFilter";
import { ClearFiltersButton } from "../Buttons/ClearFiltersButton/ClearFiltersButton";
import { ApplyFiltersButton } from "../Buttons/ApplyFiltersButton/ApplyFiltersButton";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { initialAppState } from "../../core/appReducer";
import { SearchFilter } from "./SearchFilter/SearchFilter";
import { useAdminContext } from "../../hooks/useAdminContext/useAdminContext";

interface FilterProps {
	dateFilters?: boolean
	bookingFilters?: boolean;
	conferenceFilters?: boolean;
	showApply?: boolean
}

export const Filter: FC<FilterProps> = ({ dateFilters = true, bookingFilters = true, conferenceFilters = true, showApply = true }) => {
	const { appState } = useAppState();
	const { getBookings } = useAdminContext();

	const onSubmit = (event: any) => {
		event?.preventDefault();
		getBookings();
	};

	return (
		<Box marginY="space60" display="flex" justifyContent="space-between" flexDirection={["column", "column", "row"]} columnGap="space60" rowGap="space60"
			alignItems="end" flexWrap="wrap" width="100%">
			<form style={{ width: "100%" }} onSubmit={onSubmit}>
				<Box display="flex" flexDirection="row" columnGap="space60" rowGap="space60" flexWrap="wrap" justifyContent="space-between" width="100%">
					<Box display="flex" flexDirection="row" columnGap="space60" rowGap="space60" flexWrap="wrap">
						{bookingFilters && <SearchFilter />}
						{conferenceFilters && <ConferenceTypeFilter />}
						{bookingFilters && <StatusFilter />}
						{bookingFilters && <RegionFilter />}
						<HostFilter />
						{(conferenceFilters && dateFilters) && <DateTimeFilter />}
					</Box>
					{showApply && <Box display="flex" flexDirection="row" columnGap="space60" alignItems="flex-end">
						<Box display="flex" flexDirection="row" columnGap="space60" alignItems="center">
							{(appState.filters !== initialAppState.filters) && <ClearFiltersButton />}
							<ApplyFiltersButton />
						</Box>
					</Box>}
				</Box>
			</form>
		</Box>
	);
};
