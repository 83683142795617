import { FC } from "react";
import { Spinner } from "@twilio-paste/core/spinner";
import { Box } from "@twilio-paste/core/box";

export const Loading: FC = () => {
	return (
		<Box>
			<Spinner decorative={false} title="Loading" />
		</Box>
	);
};
