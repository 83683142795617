import { Duration } from "luxon";

// function to capatilise first letter of a string
export const CapitaliseFirstLetter = (string: string) => {
	return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export const ProperCase = (string: string) => {
	return string.replace(/\w\S*/g, (txt) => {return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();});
}


export const secondsToHumanReadable = (seconds: any) => {

	// Create a Luxon Duration object from the seconds
	const duration = Duration.fromObject({ seconds });

	let formattedDuration = duration.toFormat("h'h' mm'm'"); // Format the duration

	// Remove leading "0h" if present
	if (formattedDuration.startsWith("0h")) {
		formattedDuration = formattedDuration.substring(2).trim();
	}

	// Remove trailing "00m" if present
	if (formattedDuration.endsWith("00m")) {
		formattedDuration = formattedDuration.substring(0, formattedDuration.length - 3).trim();
	}

	return formattedDuration; // Return the formatted duration
};
