import { FC, useEffect, useState } from "react";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/button";
import { Checkbox, CheckboxGroup } from "@twilio-paste/core/checkbox";
import { Host, HostDetail, ListSkillSet, Role, SkillSetItem } from "@mmc/conferencing-booking-client";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Select, Option } from "@twilio-paste/core/select";
import { Separator } from "@twilio-paste/core/separator";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { useNavigate } from "react-router-dom";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";

export const AddHost: FC = (props) => {
	const [checked, setChecked] = useState<string[]>([]);
	const [formState, setFormState] = useState<HostDetail>();
	const { listSkillSets, createHost } = useBookingContext();
	const [loaded, setLoaded] = useState<boolean>(false);
	const navigate = useNavigate();
	const [skillSet, setSkillSet] = useState<SkillSetItem>();
	const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
	const { toaster } = useToasterContext();

	useEffect(() => {
		(async () => {
			try {
				const data: ListSkillSet = await listSkillSets();
				if (data.skillsets.length === 1) {
					setSkillSet(data.skillsets[0]);
					setLoaded(true);
				}
			} catch (error) {
				toaster.push({
					message: "There has been an error in loading data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, [listSkillSets, toaster]);

	const onChange = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setFormState({
			...formState,
			[target.name]: target.value
		} as HostDetail);
	};

	const onCheckboxChange = ({ target }: any) => {
		if (target.checked) {
			setChecked([...checked, target.value]);
		} else {
			setChecked(checked.filter((value: string) => value !== target.value));
		}
	};

	const Submit = async (event: any) => {
		event.preventDefault();
		if (!formState) {
			return;
		}
		setSubmissionLoading(true);
		formState.skills = checked;
		try {
			const content: Host = await createHost(formState);
			if (content.hostId) {
				setSubmissionLoading(false);
				toaster.push({
					message: "Host added successfully",
					variant: "success",
					dismissAfter: 4000
				});
				navigate("/hosts", { replace: true });
			} else {
				toaster.push({
					message: "Host could not be added",
					variant: "error",
					dismissAfter: 4000
				});
				setSubmissionLoading(false);
			}
		} catch (e) {
			toaster.push({
				message: "Host could not be added",
				variant: "error",
				dismissAfter: 4000
			});
			setSubmissionLoading(false);
		}
	};

	return (
		<form onSubmit={Submit}>
			<Box
				marginY="space60"
				display="flex"
				justifyContent="start"
				flexDirection="row"
				columnGap="space60"
				alignItems="end"
			>
				<Box>
					<Label required htmlFor="firstName">First name</Label>
					<Input
						id="firstName"
						name="firstName"
						type="text"
						placeholder="John"
						onChange={onChange}
						required
					/>
				</Box>
				<Box>
					<Label required htmlFor="lastName">Last name</Label>
					<Input
						id="lastName"
						name="lastName"
						type="text"
						placeholder="Doe"
						onChange={onChange}
						required
					/>
				</Box>
				<Box>
					<Label required htmlFor="email">Email address</Label>
					<Input
						id="email"
						name="email"
						type="email"
						placeholder="john.doe@domain.com"
						onChange={onChange}
						required
					/>
				</Box>
				<Box>
					<Label htmlFor="role" required>Role</Label>
					<Select id="role" required name="role" onChange={onChange}>
						<Option value="Select a Role" defaultValue="Select a Role">Select a Role</Option>
						{Object.entries(Role).filter(([, value]) => value !== Role.Speaker && value !== Role.Viewer).map(([key, value]) => (
							<Option key={key} value={value}>{key.replace("_", " ")}</Option>
						))}
					</Select>
				</Box>
			</Box>

			<Separator orientation="horizontal" verticalSpacing="space120" />
			<Box display="flex"
				flexDirection="row"
				marginY="space60"
				flexWrap="wrap">
				{skillSet && loaded ? (
					<CheckboxGroup name="addskills" legend="Skills" helpText="Choose Host Skills">
						{skillSet.skills &&
							skillSet.skills.map((skill: { key?: string | undefined; }) => (
								<Checkbox
									key={skill.key}
									id={skill.key}
									value={skill.key}
									name={skill.key}
									onClick={onCheckboxChange}
								>
									{skill.key || "error"}
								</Checkbox>
							))}
					</CheckboxGroup>
				) : (
					<SkeletonLoader width="100px" height="100px" />
				)}
			</Box>
			<Separator orientation="horizontal" verticalSpacing="space120" />
			<Box justifyContent="end" display="flex">
				<Button type="submit" variant="primary" loading={submissionLoading}>
					Create Host
				</Button>
			</Box>
		</form>
	);
};