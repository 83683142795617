import { Input } from "@twilio-paste/core";
import { Box } from "@twilio-paste/core/dist/box";
import { Label } from "@twilio-paste/core/dist/label";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";

export const SearchFilter: FC = () => {
	const { appState, appDispatch } = useAppState();
	const { isLoading } = useAdminContext();
	const [searchQuery, setSearchQuery] = useState<string>(appState.filters.search);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.target.value);
		appDispatch({ type: "set-filters", filters: { ...appState.filters, search: e.target.value  } });
	};

	useEffect(() => {
		setSearchQuery(appState.filters.search);
	}, [ appState.filters.search ]);

	return (<Box width="330px">
		<Label htmlFor="SearchFilter">Search Term</Label>
		<Input
			aria-describedby="SearchFilter"
			id="SearchFilter"
			name="SearchFilter"
			type="text"
			placeholder="MMC ID, Booking ID, or Conference ID"
			onChange={onChange}
			value={searchQuery}
			disabled={isLoading}/>
	</Box>)
}
