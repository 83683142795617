import { createContext, FC, useCallback, useState } from "react";
import { VideoContextType } from "../../types/sdk";
import { Composition, CompositionApi, CompositionMediaApi, Configuration as VideoClientConfiguration, CompositionMediaDetail } from "@mmc/conferencing-video-client";
import { BearerTokenMiddleware } from '@mmc/apiclient-auth-middleware';
import { ReactElementProps } from "../../interface";
import { VIDEO_API_URL } from '../../constants';

type CompositionSid = Composition['compositionSid'];
type RoomSid = Composition['roomSid'];

export const VideoContext = createContext<VideoContextType>(null!);

export const VideoProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [ providerReady, setProviderReady] = useState<boolean>(false);
	const [ compositionApi, setCompositionApi] = useState<CompositionApi | null>(null);
	const [ compositionMediaApi, setCompositionMediaApi] = useState<CompositionMediaApi | null>(null);

	const connect = useCallback((authToken: string) => {
		try {
			const compositionApiInstance = new CompositionApi(
				new VideoClientConfiguration({
					basePath: VIDEO_API_URL,
					middleware: [new BearerTokenMiddleware(authToken)],
				})
			);
			setCompositionApi(compositionApiInstance);

			const compositionMediaApiInstance = new CompositionMediaApi(
				new VideoClientConfiguration({
					basePath: VIDEO_API_URL,
					middleware: [new BearerTokenMiddleware(authToken)],
				})
			);
			setCompositionMediaApi(compositionMediaApiInstance);

			setProviderReady(true);
		} catch (error: any) {
			console.error(error);
		}
	}, []);

	const createComposition = useCallback(async (roomSid: RoomSid): Promise<Composition> => {
		const composition = await compositionApi?.createComposition({ "compositionDetail": { "roomSid": roomSid } });

		if (composition) {
			return composition;
		} else {
			throw new Error("No Room Found to Create Composition");
		}
	}, [compositionApi]);

	const listCompositions = useCallback(async (roomSid: RoomSid): Promise<Composition[]> => {
		const compositions = await compositionApi?.listComposition({ "roomSid": roomSid });
		if (compositions) {
			return compositions;
		} else {
			throw new Error('No Room Found to Create Composition');
		}
	}, [compositionApi]);

	const getComposition = useCallback(async (compSid: CompositionSid): Promise<Composition> => {
		const composition = await compositionApi?.fetchComposition({ "compositionSid": compSid });
		if (composition) {
			return composition;
		} else {
			throw new Error('No Composition Found');
		}
	}, [compositionApi]);

	const downloadComposition = useCallback(async (compSid: CompositionSid): Promise<CompositionMediaDetail> => {
		const compositionMediaDetails = await compositionMediaApi?.compositionMedia({ "compositionSid": compSid });
		if (compositionMediaDetails) {
			return compositionMediaDetails;
		}
		else {
			throw new Error("No Composition Found");
		}
	}, [compositionMediaApi]);

	return <VideoContext.Provider value={{ providerReady, connect, createComposition, getComposition, downloadComposition, listCompositions }}>{children}</VideoContext.Provider>;
}